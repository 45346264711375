import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import TextLoop from 'react-text-loop';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Decoration1 from '../components/Decoration/Decoration-1';

import useResize from '../utils/useResize';

import imageSecondSectionBe from '../assets/images/vectors/home/be.png';

import imageMediaForbes from '../assets/images/vectors/media/Forbes_logo.png';
import imageMediaSiliconReview from '../assets/images/vectors/media/TheSiliconReview.svg';
import imageMediaMedium from '../assets/images/vectors/media/Wordmark_Black.png';
import imageMediaBFH from '../assets/images/vectors/media/business-for-home-LOGO.png';
import imageMediaEntrepreneur from '../assets/images/vectors/media/Entrepreneur.svg';

import SvgArrowRight from '../assets/images/vectors/icons/arrow-right-sm.inline.svg';
import SvgArrowLeft from '../assets/images/vectors/icons/arrow-left-sm.inline.svg';

import '../assets/styles/pages/home-page.scss';
import StartJourneyButton from '../components/StartJourneyButton';

const IndexPage = ({ data }) => {
  const { isTablet } = useResize();
  const prismicData = data.prismic.allHome_pages.edges[0].node;

  console.log('debug-prismicData : ', prismicData);

  return (
    <Layout>
      <SEO title="Home">
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </SEO>
      <div className="home-page">
        <FirstSection data={data} prismicData={prismicData} />
        <SecondSection data={data} prismicData={prismicData} />
        <ThirdSection isTablet={isTablet} prismicData={prismicData} />
        <FourthSection prismicData={prismicData} />
        <FifthSection data={data} prismicData={prismicData} />
        <SixthSection data={data} prismicData={prismicData} />
      </div>
    </Layout>
  );
};

const FirstSection = ({
  prismicData: {
    first_section_title,
    first_section_decoration_title,
    first_section_decoration_subtitle,
    first_section_decoration_words,
    first_section_imageSharp,
  },
}) => (
  <section className="common-first-section">
    <div className="container">
      <div className="cfs__wrapper">
        <div className="cfs__l">
          <div>
            <h1 className="c-h1 cfs__l__title">{RichText.asText(first_section_title)}</h1>
            {/* <Link to="/pricing/" className="c-btn"> */}
            {/*  Start your journey */}
            {/* </Link> */}
            <StartJourneyButton text="Start your journey" />
          </div>
        </div>
        <div className="cfs__r">
          <Decoration1 />
          <Img fluid={first_section_imageSharp.childImageSharp.fluid} />
          <div className="cfs__r__block">
            <span>{RichText.asText(first_section_decoration_title)}</span>
            <div>
              <p>{RichText.asText(first_section_decoration_subtitle)}</p>
              <TextLoop interval={2500}>
                {first_section_decoration_words.map(({ word }, index) => (
                  <b key={index}>{RichText.asText(word)}</b>
                ))}
              </TextLoop>
            </div>
          </div>
        </div>
      </div>
      <div className="cfs__media">
        <span>As Seen On</span>
        <div className="cfs__media__logos">
          <img src={imageMediaForbes} alt="" />
          <img src={imageMediaSiliconReview} alt="" />
          <img src={imageMediaMedium} alt="" />
          <img src={imageMediaBFH} alt="" />
          <img src={imageMediaEntrepreneur} alt="" />
        </div>
      </div>
    </div>
  </section>
);

const SecondSection = ({ prismicData: { second_section, second_section_imageSharp } }) => {
  const ImageWrapper = () => (
    <div className="second-section__left">
      <Img
        className="second-section__left__photo"
        fluid={second_section_imageSharp.childImageSharp.fluid}
      />
      <img className="second-section__left__be-img" src={imageSecondSectionBe} alt="be" />
    </div>
  );

  return (
    <section className="c-section-mt second-section">
      <div className="second-section__bg">
        <div className="d-lg-none">
          <ImageWrapper />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-none d-lg-block">
              <ImageWrapper />
            </div>
            <div className="col-lg-3 offset-lg-1">
              <div className="second-section__right">{RichText.render(second_section)}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ThirdSection = ({
  isTablet,
  prismicData: { third_section, third_section_image_desktop, third_section_image_mobile },
}) => (
  <section className="third-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-lg-9  mx-auto">
          <div className="third-section__wrapper">
            {RichText.render(third_section)}
            <img
              src={isTablet ? third_section_image_mobile.url : third_section_image_desktop.url}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const FourthSection = ({ prismicData: { brands } }) => {
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div className="slider-arrow slider-arrow--right" onClick={onClick}>
        <SvgArrowRight />
      </div>
    );
  }
  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="slider-arrow slider-arrow--left" onClick={onClick}>
        <SvgArrowLeft />
      </div>
    );
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <section className="fourth-section">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-9 mx-auto">
            <div className="c-caption-1">Brands We Work With</div>
            <div className="fourth-section__slider">
              <Slider {...sliderSettings}>
                {brands.map(({ image }, index) => (
                  <div key={index}>
                    <div className="fourth-section__slider__slide">
                      <img src={image.url} alt="" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FifthSection = ({
  prismicData: { fifth_section_title, fifth_section_content, fifth_section_imageSharp },
}) => (
  <section className="c-section-mt fifth-section">
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-lg-7 fifth-section__image">
          <Img fluid={fifth_section_imageSharp.childImageSharp.fluid} />
        </div>
        <div className="col-md-4 col-lg-5 fifth-section__text">
          <div className="c-h1">{RichText.asText(fifth_section_title)}</div>
          {RichText.render(fifth_section_content)}
        </div>
      </div>
    </div>
  </section>
);

const SixthSection = ({ prismicData: { sixth_section_title, sixth_section_imageSharp } }) => (
  <section className="c-section-my sixth-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-xl-11 mx-auto">
          <div className="sixth-section__wrapper">
            <div className="sixth-section__text">
              <div className="c-h1">{RichText.render(sixth_section_title)}</div>
              <StartJourneyButton text="Get started" />
            </div>
            <div className="sixth-section__img">
              <Img className="w-100" fluid={sixth_section_imageSharp.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  query HomePage {
    prismic {
      allHome_pages {
        edges {
          node {
            first_section_title
            first_section_decoration_title
            first_section_decoration_subtitle
            first_section_decoration_words {
              word
            }
            first_section_image
            first_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 816, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            second_section
            second_section_image
            second_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            third_section
            third_section_image_desktop
            third_section_image_mobile
            brands {
              image
            }
            fifth_section_title
            fifth_section_content
            fifth_section_image
            fifth_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sixth_section_title
            sixth_section_image
            sixth_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 835, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
